import { useMutation, useQuery } from "@tanstack/react-query";
import {useApi} from "./useApi";
import AccreditationClient from "../clients/AccreditationClient";

export const useGetVault = (investmentId, options = {}) => {
  const accreditation = useApi(AccreditationClient);

  return useQuery(
    ["investment-vault", investmentId],
    () => accreditation.getVault(investmentId),
    {
      ...options,
    }
  );
};

export const useUpdateVault = (investmentId, options = {}) => {
  const accreditation = useApi(AccreditationClient);

  return useMutation(
    async (data) => accreditation.updateVault(investmentId, data),
    options
  );
};

export const usePresignedUrl = (investmentId, options = {}) => {
  const accreditation = useApi(AccreditationClient);

  return useMutation(
    async (data: {vaultField: string, fileExtension: string}) =>
      accreditation.getPresignedUrl(investmentId, data.vaultField, data.fileExtension),
    options
  );
};

export const useVerificationReport = (investmentId, options = {}) => {
  const accreditation = useApi(AccreditationClient);

  return useMutation(
    async (data: {filename: string, extension: string}) =>
      accreditation.submitVerificationReport(investmentId, data.filename, data.extension),
    options
  );
};
