import {
  useMutation,
  useQuery,
  type QueryObserverOptions,
} from "@tanstack/react-query";
import HelloSign from "hellosign-embedded";
import { useCallback } from "react";

import { useApi } from "./useApi";

type UseEmbeddedSignatureParams = {
  onClose?: () => Promise<void>;
  onCancel?: () => Promise<void>;
  onFinish?: () => Promise<void>;
  onMessage?: () => Promise<{ type: string; message: string } | void>;
  onError?: () => Promise<{ signatureId: string; code: string } | void>;
  testMode?: boolean;
  skipDomainVerification?: boolean;
};

import AgreementClient from "../clients/AgreementClient";

export const useAgreementTemplates = (search?: string) => {
  const agreementClient = useApi(AgreementClient);

  return useQuery(["sign", "templates", { search }], () =>
    agreementClient.templates(search)
  );
};

export const useSignEmbeddedUrl = () => {
  const agreementApi = useApi(AgreementClient);

  return useMutation(
    ({
      investmentAgreementId,
      signatureId,
    }: {
      investmentAgreementId: string;
      signatureId: string;
    }) => agreementApi.embeddedSignatureUrl(investmentAgreementId, signatureId)
  );
};

export const useStartSignatureRequest = () => {
  const agreementApi = useApi(AgreementClient);

  return useMutation(({ investmentId, manualSetup }: { investmentId: string, manualSetup: boolean }) =>
    agreementApi.createRequest(investmentId, manualSetup  )
  );
};

export const useCancelInvestmentAgreement = () => {
  const agreementApi = useApi(AgreementClient);

  return useMutation(
    ({
      investmentId,
      investmentAgreementId,
      reason,
    }: {
      investmentId: string;
      investmentAgreementId: string;
      reason: string;
    }) =>
      agreementApi.cancelAgreement(investmentId, investmentAgreementId, reason)
  );
}

export const useEmbeddedSignature = ({
  onClose = async () => {},
  onCancel = async () => {},
  onFinish = async () => {},
  onMessage = async () => {},
  onError = async () => {},
  testMode = true,
  skipDomainVerification = true,
}: UseEmbeddedSignatureParams) => {
  const embeddedUrl = useSignEmbeddedUrl();

  const start = useCallback(
    async (investmentAgreementId: string, signatureId: string) => {
      const request = await embeddedUrl.mutateAsync({
        investmentAgreementId,
        signatureId,
      });

      const client = new HelloSign({
        clientId: request.clientId,
        testMode,
        skipDomainVerification,
      });

      client.on("close", onClose);
      client.on("cancel", onCancel);
      client.on("finish", onFinish);
      client.on("message", onMessage);
      client.on("error", onError);

      client.open(request.embedded.signUrl);
    },
    []
  );

  return {
    start,
    isLoading: embeddedUrl.isLoading,
    isSuccess: embeddedUrl.isSuccess,
    data: embeddedUrl.data,
  };
};

export const useAgreementSigner = (
  investmentAgreementId: string,
  signatureId: string,
  options: QueryObserverOptions = {}
) => {
  const agreementApi = useApi(AgreementClient);

  return useQuery({
    queryKey: ["investment", "signers", investmentAgreementId, signatureId],
    queryFn: () =>
      agreementApi.additionalSignerDetails(investmentAgreementId, signatureId),
    enabled: !!(investmentAgreementId && signatureId),
    ...options,
  });
};
